<template>
	<b-modal
		v-model="isOpen"
		:title="FormMSG(295, 'Options')"
		header-class="header-class-modal-doc-package"
		size="xl"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		@cancel="emitEventClose"
		@hidden="emitEventClose"
		@ok.prevent="handleSubmit"
		modal-class="mui-animation"
		:fade="false"
	>
		<form ref="containerEntryOptionRef">
			<b-form-radio :value="0" v-model="optionChoice" class="mt-3">
				<div class="d-flex">
					<div class="fs-14 fw-700">{{ FormMSG(158, 'Compute new entries') }} -</div>
					<div class="fs-14 fw-500 ml-1">
						{{ FormMSG(273, 'Only new carbon entries will be computed. Entries already updated by an automation will not be affected') }}
					</div>
				</div>
			</b-form-radio>
			<b-form-radio :value="1" v-model="optionChoice" class="mt-3">
				<div class="d-flex">
					<div class="fs-14 fw-700">{{ FormMSG(101, 'Compute new and automated') }} -</div>
					<div class="fs-14 fw-500 ml-1">
						{{ FormMSG(278, 'New and already automated carbon entries will be computed') }}
					</div>
				</div>
			</b-form-radio>
		</form>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" :disabled="loadingSubmit" @click="cancel" block>
					{{ FormMSG(43, 'Cancel') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="loadingSubmit" @click="ok" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-if="loadingSubmit" small />
						<div :class="`${loadingSubmit ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(42, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'RunAutomationOptionDialog',

	props: {
		open: { type: Boolean, default: false, required: false },
		carbonAutomationId: { type: [Number, null], default: null, required: false }
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			optionChoice: 0,
			loadingSubmit: false
		};
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	methods: {
		async handleSubmit() {
			this.$emit('run-automation-option-dialog:choice', {
				idSelected: this.carbonAutomationId,
				choice: this.optionChoice
			});

			this.emitEventClose();
		},
		emitEventClose() {
			this.$emit('run-automation-option-dialog:close');
		}
	}
};
</script>
