var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        title: _vm.FormMSG(295, "Options"),
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmit.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok, cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: cancel },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(43, "Cancel")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant: "primary",
                        disabled: _vm.loadingSubmit,
                        block: "",
                      },
                      on: { click: ok },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _vm.loadingSubmit
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: `${_vm.loadingSubmit ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(42, "Save")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "form",
        { ref: "containerEntryOptionRef" },
        [
          _c(
            "b-form-radio",
            {
              staticClass: "mt-3",
              attrs: { value: 0 },
              model: {
                value: _vm.optionChoice,
                callback: function ($$v) {
                  _vm.optionChoice = $$v
                },
                expression: "optionChoice",
              },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "fs-14 fw-700" }, [
                  _vm._v(
                    _vm._s(_vm.FormMSG(158, "Compute new entries")) + " -"
                  ),
                ]),
                _c("div", { staticClass: "fs-14 fw-500 ml-1" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          273,
                          "Only new carbon entries will be computed. Entries already updated by an automation will not be affected"
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-form-radio",
            {
              staticClass: "mt-3",
              attrs: { value: 1 },
              model: {
                value: _vm.optionChoice,
                callback: function ($$v) {
                  _vm.optionChoice = $$v
                },
                expression: "optionChoice",
              },
            },
            [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", { staticClass: "fs-14 fw-700" }, [
                  _vm._v(
                    _vm._s(_vm.FormMSG(101, "Compute new and automated")) + " -"
                  ),
                ]),
                _c("div", { staticClass: "fs-14 fw-500 ml-1" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.FormMSG(
                          278,
                          "New and already automated carbon entries will be computed"
                        )
                      ) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }