<template>
	<b-modal
		id="viewLogAutomationDialog"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="FormMSG(45, 'Log')"
		size="xl"
		no-close-on-backdrop
		no-close-on-esc
		cancel-variant="outline-primary"
		ok-only
		ok-variant="primary"
		:ok-title="FormMSG(569, 'Close')"
		@ok.prevent="emitEventClose"
		@hidden="emitEventClose"
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="container-layout form">
			<div class="fs-16 fw-700 mb-2">{{ FormMSG(514, 'Summary') }}</div>
			<div class="d-flex mb-2">
				<div class="fs-14 fw-500">{{ FormMSG(515, 'Number of entries processed:') }}</div>
				<div class="fs-14 fw-700">{{ nbProcessed }}</div>
			</div>
			<div class="d-flex mb-2">
				<div class="fs-14 fw-500">{{ FormMSG(516, 'Number of entries updated:') }}</div>
				<div class="fs-14 fw-700">{{ nbUpdate }}</div>
			</div>
			<div class="d-flex mb-3">
				<div class="fs-14 fw-500">{{ FormMSG(517, 'Number of entries not updated:') }}</div>
				<div class="fs-14 fw-700">{{ nbNotUpdate }}</div>
			</div>

			<b-row class="mb-2">
				<b-col cols="8">
					<div class="d-flex">
						<div class="fs-16 fw-700">{{ FormMSG(518, 'Details') }}</div>
						<div class="fs-16 ml-2">{{ logStr === '' ? ' - ' : logStr }}</div>
					</div>
				</b-col>
				<b-col cols="4">
					<b-button variant="primary" class="float-right">
						{{ FormMSG(519, 'Export detailed report') }}
					</b-button>
				</b-col>
			</b-row>

			<b-row>
				<b-col>
					<b-table
						v-if="$screen.width >= 992"
						selected-variant="primary"
						hover
						selectable
						select-mode="single"
						responsive="sm"
						ref="documentPackageTable"
						sticky-header="500px"
						:items="dataList"
						style="text-align: left"
						:fields="greenTableFields"
						bordered
						striped
						small
						head-variant="dark"
						:empty-text="FormMSG(250, 'No data found')"
						show-empty
						tbody-tr-class="p-2"
					>
						<template #cell(date)="{ item }">
							<div>
								{{ item.date | formatDate('DD/MM/YYYY') }}
							</div>
						</template>
						<template #cell(amount)="{ item }">
							<div>
								{{ item.amount | formatCurrency }}
							</div>
						</template>
						<template #cell(coTwo)="{ item }">
							<div>
								{{ item.coTwo | formatRendKg }}
							</div>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button size="md" variant="custom-outline-gray" style="margin-top: 5px" class="w-138-px mr-3" @click="ok" block>
					{{ FormMSG(43, 'Close') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment/moment';
import { rendCurrency, rendKgCo2 } from '~helpers';
import _ from 'lodash';

export default {
	name: 'ViewLogAutomationDialog',

	props: {
		open: { type: Boolean, default: false, required: false },
		logData: { type: Object, default: () => ({}), required: false }
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			dataList: [],
			nbProcessed: 0,
			nbUpdate: 0,
			nbNotUpdate: 0,
			logStr: ''
		};
	},

	watch: {
		logData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.nbProcessed = newVal.nbrProcessed;
					this.nbUpdate = newVal.nbrUpdate;
					this.nbNotUpdate = newVal.nbrNotUpdate;
					this.logStr = newVal.log;
					this.dataList = newVal.carbons;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		greenTableFields() {
			let result = [
				{
					key: 'strDate',
					label: this.FormMSG(165, 'Date'),
					formatter: (value) => {
						return moment(new Date(value.split('T')[0])).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'w-75-px'
				},
				{
					key: 'entityType',
					label: this.FormMSG(200, 'Source'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(11006, parseInt(value, 10));
					},
					sortable: true
				},
				{
					key: 'coTwoCategory',
					label: this.FormMSG(202, 'CO2 type'),
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1334, parseInt(value, 10));
					},
					sortable: true
				},
				{
					key: 'supplier.name',
					label: this.FormMSG(203, 'Supplier'),
					sortable: true
				},
				{
					key: 'description',
					label: this.FormMSG(201, 'Description'),
					sortable: true
				},
				{
					key: 'amount',
					label: this.FormMSG(207, 'Amount'),
					formatter: (value) => {
						return rendCurrency(parseFloat(value));
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'updated',
					label: this.FormMSG(259, 'Updated ?'),
					formatter: (value) => {
						return value === true ? this.FormMSG(501, 'Yes') : this.FormMSG(502, 'No');
					},
					sortable: true
				},
				{
					key: 'carbonTemplate.name',
					label: this.FormMSG(391, 'Template'),
					formatter: (value) => {
						return value;
					},
					sortable: true
				},
				{
					key: 'carbonTemplate.position',
					label: this.FormMSG(392, 'Template position'),
					formatter: (value) => {
						if (value === 0) return '';
						return value;
					},
					sortable: true
				}
			];

			return result;
		}
	},

	methods: {
		emitEventClose() {
			this.$emit('view-log-automation:close');
		}
	}
};
</script>

<style scoped></style>
